@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {

    .container {
        width: 100%;
    }

    @media (min-width: 375px) {
        .container {
            max-width: 375px;
        }
    }

    @media (min-width: 640px) {
        .container {
            max-width: 640px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 768px;
        }
    }

    @media (min-width: 1024px) {
        .container {
            max-width: 1024px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            max-width: 1280px;
        }
    }

    @media (min-width: 1536px) {
        .container {
            max-width: 1280px;
        }
    }

    .centralize {
        @apply flex justify-center items-center;
    }

    .centralize-lg {
        @apply lg:flex lg:justify-center lg:items-center;
    }

    .card {
        @apply py-8 px-4 rounded-4xl bg-gray-light text-dark-font max-w-sm lg:max-w-xl w-full md:mx-auto text-left;
    }

    .card-app {
        @apply
        flex flex-col justify-center items-center
        bg-secondary-dark rounded-4xl text-light-font
        py-4 px-6 text-center w-full lg:w-64 h-64;
    }

    .alert-danger {
        @apply bg-red-200 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative;
    }

    .alert-danger hr {
        @apply border-red-400;
    }

    .alert-success {
        @apply bg-green-200 border border-green-400 text-green-700 px-4 py-3 rounded-lg relative;
    }

    .alert-success hr {
        @apply border-green-400;
    }

    .alert-info {
        @apply bg-blue-200 border border-blue-400 text-blue-700 px-4 py-3 rounded-lg relative;
    }

    .alert-info hr {
        @apply border-blue-400;
    }

    .navbar {
        @apply
        grid items-center justify-between
        w-full max-h-screen py-4 md:py-0 px-4 text-lg
        text-dark-font bg-white;
    }

    @variants responsive {
        .mobile-navbar {
            grid-template-areas: "logo menu-button" "menu menu";
            grid-template-rows: auto 1fr;
        }

        .desktop-navbar {
            grid-template-areas: "logo menu";
            grid-template-rows: auto;
        }
    }

    .navbar .navbar-logo {
        @apply justify-self-start;
        grid-area: logo;
    }

    .navbar .navbar-menu-button {
        @apply justify-self-end;
        grid-area: menu-button;
    }

    .navbar .navbar-menu {
        grid-area: menu;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        @apply rounded-b-lg shadow-sm hidden fixed top-24 mt-1 left-0 right-0 text-sm w-full font-medium bg-white;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 100;
    }

    .dropdown-content a {
        @apply text-dark-font block py-1 font-normal;
    }

    .dropdown-content a:hover {
        @apply text-gradient-400 font-semibold;
    }

    .dropdown-content-open {
        @apply grid grid-flow-col grid-rows-4 2xl:grid-rows-5 gap-2 py-10 px-12;
    }

    .section {
        @apply px-4 py-8 lg:py-28;
    }

    .btn-outline {
        @apply
        bg-transparent w-full lg:w-96 py-2 px-4
        rounded-2xl border-solid
        border-2 border-white
        text-light-font font-bold text-lg
        hover:bg-gray-300 hover:bg-opacity-20;
    }

    .btn-outline-dark {
        @apply
        bg-transparent py-2 px-4
        rounded-2xl border-solid
        border border-secondary
        text-secondary font-bold
        text-lg w-full lg:w-96
        hover:bg-opacity-80
    }

    .btn-secondary {
        @apply
        bg-secondary-dark
        font-semibold
        text-light-font
        text-lg lg:w-96
        py-2 px-4
        rounded-2lg hover:bg-opacity-75;
    }


    .btn-green {
        @apply
        bg-green-btn
        font-semibold
        text-dark-font
        drop-shadow-md
        text-lg py-2 px-4 lg:w-96
        rounded-2lg hover:bg-opacity-60;
    }

    .btn-green:disabled {
        background-color: #d4d4d4; 
        color: #a1a1a1;
        cursor: not-allowed;
    }

    .link-btn-outline {
        @apply
        block text-center
        w-full py-2 px-4
        rounded-2xl border-solid
        border-2 border-white
        text-light-font font-bold text-lg;
    }

    .link-btn-outline-dark {
        @apply
        block text-center
        w-full py-2 px-4
        rounded-2xl border-solid
        border border-secondary
        text-secondary font-bold text-lg;
    }

    .custom-input {
        @apply p-2 rounded-2lg w-full border-gray-600;
    }

    .custom-input-file {
        @apply block w-full bg-white rounded-2lg m-1 border-gray-600 border;
    }

    .custom-label {
        @apply text-dark-font text-base font-medium mb-1;
    }

    .tw-title {
        size: 1.35rem;
        @apply font-medium mb-4 text-dark-font;
    }

    .tw-p {
        @apply mb-4 text-dark-font text-sm;
    }

    .title-light {
        @apply text-4xl md:text-5xl leading-tight font-bold text-light-font break-words;
    }

    .title-dark {
        @apply text-4xl md:text-5xl leading-tight font-bold text-dark-font break-words;
    }

    .card-overlay {
        z-index: 65;
        @apply fixed top-0 left-0 w-screen h-screen backdrop-filter backdrop-blur-md;
    }

    .modal-overlay {
        z-index: 55;
        @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-50;
    }

    .modal-container {
        z-index: 60;
        transform: translate(-50%, -50%);
        @apply fixed top-1/2 left-1/2 shadow-2xl;
    }

    .text-warp-balance {
        text-wrap: balance;
    }

    .card-news {
        @apply  bg-gray-light rounded-4xl flex flex-col h-96 flex-1 tracking-tight cursor-pointer overflow-hidden;
    }

    .news-budget {
        @apply bg-green-btn text-dark-font font-semibold px-2 rounded-xl rounded-tr-xl truncate xl:max-w-news-budget-width;
    }

    summary {
        list-style: none;
    }

    summary::-webkit-details-marker {
        display: none;
    }

    .summary-faq {
        @apply font-semibold py-2 px-4 text-lg flex flex-row justify-between;
    }

    .checkbox-round {
        border-radius: 50%;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        @apply w-5 h-5 bg-white align-middle
        border border-solid border-primary
        appearance-none cursor-pointer;
    }

    .checkbox-round:checked {
        @apply bg-primary bg-check-item-light-icon bg-cover;
    }

    .active {
        @apply text-primary font-semibold;
    }

    .section-hero {
        @apply lg:h-hero bg-no-repeat bg-cover bg-fixed;
    }

    .segments-hero {
        position: relative;
        top: 0;
        right: 0;
    }

    .segments-section {
        @apply h-hero relative 
        bg-cover bg-center lg:bg-secondary-dark 
        flex flex-row lg:segments-hero-container;
    }

    @variants responsive {
        .segments-hero-container {
            --container-width: 1280px;
            --section-padding-left: 1rem;
            /**
             * A ideia é fazer um container que tenha o mesmo espaçamento a esquerda que um .container,
             * porém esteja livre a direita (para podermos colocar a imagem do hero)
             */
            padding-left: calc((100vw - var(--container-width)) / 2 + var(--section-padding-left));
        }
    }

    /* carousel */
    .wrapper {
        position: relative;
        &:before,
        &:after {
            position: absolute;
            top: 0;
            z-index: 1;
            content: "";
            display: block;
            width: 16px;
            height: 100%;
        }

        &:before {
            left: 0;
            background: linear-gradient(90deg, #F5F5F5, transparent);
        }

        &:after {
            right: 0;
            background: linear-gradient(-90deg, #F5F5F5, transparent);
        }
    }

    .carousel-items {
        @apply relative overflow-hidden whitespace-nowrap cursor-pointer;

        &.active {
            cursor: grab;
        }
    }

    .carousel-item {
        user-select: none;
        @apply inline-block p-4 lg:p-0 shadow-2xl rounded-4xl md:rounded-none md:shadow-lg lg:shadow-none;

        &:last-child {
            margin-right: 16px;
        }
    }

    .img-slider {
        position: relative;
        width: 100vw;
        height:90%;
    }
    .img-slider .slider-container {
        position: absolute;
        width: 300%;
        top: 0;
        left: 0;
        overflow: hidden;
        animation: slider 26s infinite;
        will-change: transform;
    }
    .img-slider .slider-container .slide {
        position: relative;
        width: 33.33333333%;
        height: 100%;
        float: left;
        overflow: hidden
    }
    .img-slider .slider-container .slide img {
      display: block;
      width: 100%;
      height: 100%
    }
    @keyframes slider {
        0%, 25%, 100% {
            transform: translateX(0);
        }

        30%, 55% {
            transform: translateX(-100vw);
        }

        60%, 85% {
            transform: translateX(-200vw);
        }
    }

    .navigation-mode-container {
        @apply lg:absolute lg:top-0 lg:left-0 lg:w-full lg:h-full;
    }

    .navigation-mode {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* carrousel videos cases */

    .wrapper-cases {
        @apply relative overflow-hidden w-full 3xs:h-120;
    }

    .carousel-cases {
        transition: all 1s ease;
        @apply w-full flex absolute left-0;
    }

    .video-case {
        @apply mr-4 inline-block lg:px-4;
    }

    .video-case-iframe {
        @apply aspect-ratio-4-3;
        /* Não rola usar 100% aqui, tem que calcular usando unidades absolutas */
        width: calc(100vw - 4rem);
    }

    @screen sm {
        .video-case-iframe {
        @apply aspect-ratio-4-3;
        /* Não rola usar 100% aqui, tem que calcular usando unidades absolutas */
        height: calc(24rem - 4rem);
        }
    }

    @screen sm {
        .wrapper-cases {
            height: 30rem;
        }
    }

    @screen md {
        .wrapper-cases {
            height: 30rem;
        }
    }

    @screen lg {
        .wrapper-cases {
            height: 25rem;
        }

        .video-case-iframe {
            @apply lg:w-video-case;
        }

        .video-case-highlight {
            transform: scale(1.1);
        }
    }

    @screen xl {
        .wrapper-cases {
            height: 28rem;
        }
    }

    @media (min-width: 1280px) {
        .video-case-highlight {
            transform: scale(1.2);
        }
    }

    /* carrousel partners */

    .wrapper-partners {
        @apply relative overflow-hidden w-full h-80 3xs:h-120 ;
    }

    .carousel-partners {
        transition: all 1s ease;
        @apply w-full flex absolute left-0;
    }

    .video-partner {
        @apply mr-4 inline-block lg:px-4;
    }

    .video-partner-iframe {
        @apply aspect-ratio-4-3;
        /* Não rola usar 100% aqui, tem que calcular usando unidades absolutas */
        width: calc(100vw - 4rem);
    }

    @screen sm {
        .video-partner-iframe {
            @apply aspect-ratio-4-3;
            /* Não rola usar 100% aqui, tem que calcular usando unidades absolutas */
            height: calc(24rem - 4rem);
        }
    }

    @screen sm {
        .wrapper-partners {
            height: 30rem;
        }
    }

    @screen md {
        .wrapper-partners {
            height: 30rem;
        }
    }

    @screen lg {
        .wrapper-partners {
            height: 30rem;
        }

        .video-partner-iframe {
            @apply lg:w-video-case;
        }
    }

    @media (min-width: 1280px) {
        .video-partner-highlight {
            transform: scale(1.2);
        }
    }

    /* generic carousel */
    .flex-carousel-items-wrapper {
        @apply overflow-x-hidden w-full;
    }

    .flex-carousel-items {
        transition: all 1s ease;
        @apply w-full flex;
        gap: var(--gap-size);
    }

    .flex-carousel-item {
        @apply flex flex-grow-0 flex-shrink-0;
        /* O tamanho do flex-basis é o tamanho de cada card individualmente, 
         * desconsidando o espaçamento entre cada card. */
        flex-basis: calc((100% - (var(--number-of-cards) - 1) * var(--gap-size)) / var(--number-of-cards));
    }

    /* carrousel comments */
    .wrapper-comments {
        @apply flex-carousel-items-wrapper;
    }

    .carousel-comments {
        @apply flex-carousel-items;
        --gap-size: 2rem;
        /* 1 comentário na tela em mobile, 2 em tablet e 3 em desktop */
        --number-of-cards: 1;
    }

    .comments {
        @apply flex-carousel-item;
    }

    @screen md {
        .carousel-comments {
            --number-of-cards: 2;
        }
    }

    @screen lg {
        .carousel-comments {
            --number-of-cards: 3;
        }
    }

    .carousel-tools-with-buttons {
        width: 100%;
        display: grid;
        grid-template-columns: min-content 1fr min-content;
        gap: 1rem;
    }

    .wrapper-tools {
        @apply flex-carousel-items-wrapper;
    }

    .carousel-tools {
        @apply flex-carousel-items;
        --gap-size: 1rem;
        /* 1 comentário na tela em mobile, 3 em tablet e 5 em desktop */
        --number-of-cards: 1;
    }

    .carousel-tool {
        @apply flex-carousel-item;
    }

    @screen md {
        .carousel-tools {
            --number-of-cards: 2;
        }
    }

    @screen lg {
        .carousel-tools {
            --number-of-cards: 3;
        }
    }

    @screen xl {
        .carousel-tools {
            --number-of-cards: 4;
        }
    }

    .carousel-item-card {
        transform: translate(-50%, -50%);
        @apply block absolute top-1/2 left-1/2 w-full;
    }

    .slider-outside {
        @apply mx-16;

        @screen lg {
            width: calc(1024px - 10rem);
        }

        @screen xl {
            width: 72rem;
        }
    }

    #slider {
        @apply relative overflow-hidden h-20 xl:h-24;

        @screen lg {
            width: calc(1024px - 10rem);
        }

        @screen xl {
            width: 70rem;
        }
    }

    #slider ul {
        @apply relative list-none;

        @screen lg {
            width: calc(1024px - 10rem);
        }

        @screen xl {
            width: 70rem;
        }
    }

    #slider ul li {
        @apply relative float-left h-20 xl:h-24 flex justify-between items-center;

        @screen lg {
            width: calc(1024px - 12rem);
        }

        /* @screen xl {
            min-width: calc(1280px - calc(10vw + 10rem));
            max-width: calc(1280px - 10vw);
        } */

        @screen xl {
            width: 70rem;
        }
    }

    #slider ul li div {
        @apply px-1;
    }


    /* Inputs */
    input[type=file]::file-selector-button {
        @apply
        border-0
        bg-gradient-200
        font-semibold 
        text-light-font 
        cursor-pointer
        rounded-2lg py-2
        transition-opacity;
    }

    input[type=file]::file-selector-button:hover {
        @apply bg-gradient-200;
    }

    /*********** Baseline, reset styles ***********/
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
    }

    /* Removes default focus */
    input[type="range"]:focus {
        outline: none;
    }

    /******** Chrome, Safari, Opera and Edge Chromium styles ********/
    /* slider track */
    input[type="range"]::-webkit-slider-runnable-track {
        background-color: #00AFF2;
        border-radius: 0.825rem;
        height: 0.825rem;
    }

    /* slider thumb */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: 0px; /* Centers thumb on the track */
        background-color: #ffffff;
        border-radius: 0.5rem;
        height: 0.825rem;
        width: 0.825rem;
    }

    input[type="range"]:focus::-webkit-slider-thumb {
        outline: 3px solid #ffffff;
        outline-offset: 0.125rem;
    }

    /*********** Firefox styles ***********/
    /* slider track */
    input[type="range"]::-moz-range-track {
        background-color: #00AFF2;
        border-radius: 0.825rem;
        height: 0.825rem;
    }

    /* slider thumb */
    input[type="range"]::-moz-range-thumb {
        background-color: #ffffff;
        border: none; /*Removes extra border that FF applies*/
        border-radius: 0.5rem;
        height: 0.825rem;
        width: 0.825rem;
    }

    input[type="range"]:focus::-moz-range-thumb{
        outline: 3px solid #ffffff;
        outline-offset: 0.125rem;
    }

    /* icons */
    .left-arrow-icon {
        @apply bg-left-arrow-icon bg-cover block;
    }

    .right-arrow-icon {
        @apply bg-right-arrow-icon bg-cover block;
    }

    /* partners icons */
    .commercial-icon {
        @apply bg-commercial-icon bg-cover h-8 w-8 block;
    }

    .gamification-icon {
        @apply bg-gamification-icon bg-cover h-8 w-8 block;
    }

    .marketing-icon {
        @apply bg-marketing-icon bg-cover h-8 w-8 block;
    }

    .support-partner-icon {
        @apply bg-support-partner-icon bg-cover h-8 w-8 block;
    }

    .training-partner-icon {
        @apply bg-training-partner-icon bg-cover h-8 w-8 block;
    }

    .reports-icon {
        @apply bg-reports-icon bg-cover h-8 w-8 block;
    }

    .check-icon {
        @apply bg-check-item-icon h-5 w-5 block;
    }

    .check-item-light-icon {
        @apply bg-check-item-light-icon h-5 w-5 block;
    }
    .upgrade-icon {
        @apply bg-upgrade-icon bg-cover h-8 w-8 block;
    }

    .financial-2-icon {
        @apply bg-upgrade-icon bg-cover h-8 w-8 block;
    }

    .support-icon {
        @apply bg-support-icon bg-cover h-8 w-8 block;
    }

    .facebook-icon {
        @apply bg-facebook-icon bg-cover h-8 w-8 block;
    }

    .instagram-icon {
        @apply bg-instagram-icon bg-cover h-8 w-8 block;
    }

    .youtube-icon {
        @apply bg-youtube-icon bg-cover h-8 w-8 block;
    }

    .linkedin-icon {
        @apply bg-linkedin-icon bg-cover h-8 w-8 block;
    }

    .chevron-up-icon {
        @apply bg-chevron-up bg-cover h-8 w-8 block;
    }

    .chevron-down-icon {
        @apply bg-chevron-down bg-cover h-8 w-8 block;
    }

    .chevron-icon {
        transition: transform 0.3s ease-in-out;
    }

    .chevron-up {
        transform: rotate(180deg);
    }

    .email-icon {
        @apply bg-email-icon;
    }

    .phone-icon {
        @apply bg-phone-icon bg-cover h-5 w-5 block;
    }

    .whatsapp-icon {
        @apply bg-whatsapp-icon bg-cover h-5 w-5 block;
    }

    .hamburguer-icon {
        @apply bg-hamburguer-icon bg-cover h-5 w-5 block;
    }

    .acai-icon {
        @apply bg-acai-icon bg-cover h-5 w-5 block;
    }

    .acougue-icon {
        @apply bg-acougue-icon bg-cover h-5 w-5 block;
    }

    .adega-icon {
        @apply bg-adega-icon bg-cover h-5 w-5 block;
    }

    .auto-pecas-icon {
        @apply bg-auto-pecas-icon bg-cover h-5 w-5 block;
    }

    .bar-balada-icon {
        @apply bg-bar-balada-icon bg-cover h-5 w-5 block;
    }

    .cafeteria-icon {
        @apply bg-cafeteria-icon bg-cover h-5 w-5 block;
    }

    .distribuidora-icon {
        @apply bg-distribuidora-icon bg-cover h-5 w-5 block;
    }

    .food-truck-icon {
        @apply bg-food-truck-icon bg-cover h-5 w-5 block;
    }

    .lanchonete-icon {
        @apply bg-lanchonete-icon bg-cover h-5 w-5 block;
    }

    .calcados-icon {
        @apply bg-calcados-icon bg-cover h-5 w-5 block;
    }

    .produtos-naturais-icon {
        @apply bg-produtos-naturais-icon bg-cover h-5 w-5 block;
    }

    .acai-icon {
        @apply bg-acai-icon bg-cover h-5 w-5 block;
    }

    .roupas-icon {
        @apply bg-roupas-icon bg-cover h-5 w-5 block;
    }

    .suplementos-icon {
        @apply bg-suplementos-icon bg-cover h-5 w-5 block;
    }

    .mercado-icon {
        @apply bg-mercado-icon bg-cover h-5 w-5 block;
    }

    .panificadora-icon {
        @apply bg-panificadora-icon bg-cover h-5 w-5 block;
    }

    .papelaria-icon {
        @apply bg-papelaria-icon bg-cover h-5 w-5 block;
    }

    .pizzaria-icon {
        @apply bg-pizzaria-icon bg-cover h-5 w-5 block;
    }

    .restaurante-icon {
        @apply bg-restaurante-icon bg-cover h-5 w-5 block;
    }

    .sorveteria-icon {
        @apply bg-sorveteria-icon bg-cover h-5 w-5 block;
    }

    .cama-mesa-banho-icon {
        @apply bg-cama-mesa-banho-icon bg-cover h-5 w-5 block;
    }

    .embalagens-icon {
        @apply bg-embalagens-icon bg-cover h-5 w-5 block;
    }

    .confeitaria-icon {
        @apply bg-confeitaria-icon bg-cover h-5 w-5 block;
    }

    .comment-rate-icon {
        @apply bg-comment-rate-icon bg-cover h-2 w-2 block;
    }

    .fiscal-icon {
        @apply bg-fiscal-icon bg-cover h-2 w-2 block;
    }

    .cloud-icon {
        @apply bg-cloud-icon bg-cover h-2 w-2 block;
    }

    .wifiless-icon {
        @apply bg-wifiless-icon bg-cover h-2 w-2 block;
    }

    .delivery-icon {
        @apply bg-delivery-icon bg-cover h-2 w-2 block;
    }

    .financial-icon {
        @apply bg-financial-icon bg-cover h-2 w-2 block;
    }

    .cards-icon {
        @apply bg-cards-icon bg-cover h-2 w-2 block;
    }

    .users-icon {
        @apply bg-users-icon bg-cover h-2 w-2 block;
    }

    .xml-icon {
        @apply bg-xml-icon bg-cover h-2 w-2 block;
    }

    .balance-scale-icon {
        @apply bg-balance-scale-icon bg-cover h-2 w-2 block;
    }

    .schedules-icon {
        @apply bg-schedules-icon bg-cover h-2 w-2 block;
    }

    .companies-icon {
        @apply bg-companies-icon bg-cover h-2 w-2 block;
    }

    .financial-release-icon {
        @apply bg-financial-release-icon bg-cover h-2 w-2 block;
    }

    .financial-growup-icon {
        @apply bg-financial-growup-icon bg-cover h-2 w-4 block;
    }

    .stock-icon {
        @apply bg-stock-icon bg-cover h-2 w-2 block;
    }

    .pdv-setup-icon {
        @apply bg-pdv-setup-icon bg-cover h-2 w-2 block;
    }

    .holding-cellphone-icon {
        @apply bg-holding-cellphone-icon bg-cover h-2 w-2 block;
    }

    .erp-integration-icon {
        @apply bg-erp-integration-icon bg-cover h-2 w-2 block;
    }

    .hand-signaling-icon {
        @apply bg-hand-signaling-icon bg-cover h-2 w-2 block;
    }

    .kitchen-orders-icon {
        @apply bg-kitchen-orders-icon bg-cover h-2 w-2 block;
    }

    .loyalty-icon {
        @apply bg-loyalty-icon bg-cover h-2 w-2 block;
    }

    .totem-icon {
        @apply bg-totem-icon bg-cover h-2 w-2 block;
    }

    .touchscreen-icon {
        @apply bg-touchscreen-icon bg-cover h-2 w-2 block;
    }

    .waiter-icon {
        @apply bg-waiter-icon bg-cover h-2 w-2 block;
    }

    .mercado-autonomo-icon {
        @apply bg-mercado-autonomo-icon bg-cover h-2 w-2 block;
    }

    .quiosque-icon {
        @apply bg-quiosque-icon bg-cover h-2 w-2 block;
    }

    .loading-icon {
        @apply bg-loading-icon bg-cover h-3 w-3 block;
    }

    .news-section {
        /* Cria um background com 2 cores sólidas, uma metade com a cor de cima e a outra com a cor de baixo */
        background: linear-gradient(
            to bottom,
            var(--top-bg-color) 50%,
            var(--bottom-bg-color) 50%
        );

        /* Usamos o padding superior e inferior dos elementos adjacentes, pra parecer que estamos
         "entre" eles */
        padding-top: 0;
        padding-bottom: 0;
    }

    .news-section.top-bg-primary {
        --top-bg-color: theme('colors.primary');
    }

    .news-section.top-bg-secondary {
        --top-bg-color: theme('colors.secondary');
    }

    .news-section.top-bg-secondary-dark {
        --top-bg-color: theme('colors.secondary-dark');
    }

    .news-section.top-bg-gray-light {
        --top-bg-color: theme('colors.gray-light');
    }

    .news-section.bottom-bg-primary {
        --bottom-bg-color: theme('colors.primary');
    }

    .news-section.bottom-bg-secondary {
        --bottom-bg-color: theme('colors.secondary');
    }

    .news-section.bottom-bg-secondary-dark {
        --bottom-bg-color: theme('colors.secondary-dark');
    }

    .news-section.bottom-bg-gray-light {
        --bottom-bg-color: theme('colors.gray-light-100');
    }

    /* Pagina de autoatendimento */
    .image-hero-height {
        height: 37.188rem;
    }

    .tabs-plans li:last-child {
        @apply ml-px md:ml-4 lg:ml-10 xl:ml-16;
        /* margin-left: 8rem;  */
    }

    .plan-card {
        transition: all .2s ease-in-out; 

        @apply bg-gray-light border-solid border border-primary rounded-4xl py-4 px-5 xl:w-96 lg:h-card-plan-lg;
    }

    .plan-card:hover {
        transform: scale(1.1); 

        @apply border-primary border-4;
    }

    .icon-modal {
        position: relative;
        margin: 0 auto 10px;
        width: 50px;
    }
    
    .btn-callme {
        @apply btn-green;
    }
    
    .btn-whatsapp {
        @apply 
            font-semibold
            drop-shadow-md
            text-lg py-2 px-4 lg:w-96
            rounded-2lg hover:bg-opacity-60;
        background-color: #1DAA61;
        color: #fff;
    }

    .button-modal-whatsapp {
        cursor: pointer;
    }
}

@layer utilities {
    @variants responsive {
        .aspect-ratio-4-3 {
            aspect-ratio: 4/3;
        }

        .text-balance {
            text-wrap: balance;
        }
    }
}
